import { KeyboardEvent, useRef } from 'react'
import { Button } from '../Button'

export type Props = {
  /**
   * function called when a valid postcode is entered and search button is clicked
   */
  onSearch: VoidFunction

  /**
   * show pulse effect on search button
   */
  showPulse: boolean

  /**
   * show search button in loading state
   */
  loading: boolean
  /**
   * Boolean indicating whether to show error styles on the input element
   */
  error?: boolean
  /**
   * Boolean indicating whether to show success styles on the input element
   */
  success?: boolean
  /**
   * Helper text for the input field
   */
  helper?: React.ReactNode
} & Omit<React.HTMLProps<HTMLInputElement>, 'className'>
const PostcodeInput = (props: Props) => {
  const submitRef = useRef<HTMLButtonElement | null>(null)
  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.currentTarget.blur()
      submitRef.current?.click()
    }
  }
  return (
    <div className={`form-group sm:col-span-2 ${props.error ? 'error' : props.success ? 'success' : ''}`}>
      <label htmlFor={props.id} className="mb-2 inline-block text-lg font-bold text-gray-900 dark:text-white">
        {props.label}
      </label>
      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <svg
            aria-hidden="true"
            className="h-5 w-5 text-gray-500 dark:text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
        </div>
        <input
          type="text"
          name="postCode"
          placeholder={props.placeholder}
          className=" block w-full rounded-lg border border-gray-300 bg-gray-50 p-4 pl-10 text-lg text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:placeholder-opacity-75 dark:focus:border-blue-500 dark:focus:ring-blue-500"
          value={props.value}
          onChange={props.onChange}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onKeyDown={handleKeyPress}
        />
        <Button
          disabled={props.disabled}
          size="sm"
          variant="default"
          className={`${props.showPulse ? 'search-pulse' : ''} absolute bottom-1/2 right-3 inline-flex translate-y-1/2`}
          onClick={event => {
            event.preventDefault()
            props.onSearch()
          }}
          ref={submitRef}
        >
          {props.loading ? (
            <>
              <svg
                aria-hidden="true"
                role="status"
                className="me-3 inline h-4 w-4 animate-spin text-gray-200 dark:text-gray-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="#1C64F2"
                />
              </svg>
              Loading...
            </>
          ) : (
            <>
              Search
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                className="fill-gray-200 dark:fill-gray-900"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.293 5.29303C12.4805 5.10556 12.7348 5.00024 13 5.00024C13.2652 5.00024 13.5195 5.10556 13.707 5.29303L17.707 9.29303C17.8945 9.48056 17.9998 9.73487 17.9998 10C17.9998 10.2652 17.8945 10.5195 17.707 10.707L13.707 14.707C13.5184 14.8892 13.2658 14.99 13.0036 14.9877C12.7414 14.9854 12.4906 14.8803 12.3052 14.6948C12.1198 14.5094 12.0146 14.2586 12.0123 13.9964C12.01 13.7342 12.1108 13.4816 12.293 13.293L14.586 11H3C2.73478 11 2.48043 10.8947 2.29289 10.7071C2.10536 10.5196 2 10.2652 2 10C2 9.73481 2.10536 9.48046 2.29289 9.29292C2.48043 9.10539 2.73478 9.00003 3 9.00003H14.586L12.293 6.70703C12.1055 6.5195 12.0002 6.26519 12.0002 6.00003C12.0002 5.73487 12.1055 5.48056 12.293 5.29303Z"
                />
              </svg>
            </>
          )}
        </Button>
      </div>
      {props.helper}
    </div>
  )
}

export default PostcodeInput
